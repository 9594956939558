import axios from "axios"

export const getProfile = () => {
  return axios.get(process.env.REACT_APP_API_URI+'profile', {
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    }
  }).then((response) => {
    return response.data
  })
}

export const updateProfile = (data) => {
  return axios.put(process.env.REACT_APP_API_URI+'profile/'+ data.id, data, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      'Content-Type': 'multipart/form-data'
    }
  }).then((response) => {
    return response.data
  })
}