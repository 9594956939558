import { Slide, toast } from "react-toastify"

export const toastError = (message) => {
  toast.error(message, {
    transition: Slide
  })
}

export const toastSuccess = (message) => {
  toast.success(message, {
    transition: Slide
  })
}

export const toastWarning = (message) => {
  toast.warning(message, {
    transition: Slide
  })
}

export const toastInfo = (message) => {
  toast.info(message, {
    transition: Slide
  })
}
