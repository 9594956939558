import React from 'react'
import CircleButton from '../atoms/CircleButton'

export default function FlyingAddButton(props) {
  const {hidden, setHidden, children, onClick = () => {}} = props
  return (
    <div onClick={onClick}>
      <div className="block md:hidden fixed bottom-[15vh] right-6 z-20">
        <CircleButton hidden={hidden} setHidden={setHidden}>{children}</CircleButton>
      </div>
      <div className="hidden md:block absolute bottom-[15vh] right-6 z-20">
        <CircleButton hidden={hidden} setHidden={setHidden}>{children}</CircleButton>
      </div>
    </div>
  )
}
