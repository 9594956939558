import { QueryCache, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 100_000
    }
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.log(error);
    }
  })
})

export default queryClient;