import bca from '../../assets/images/logo/bca.png'
import bri from '../../assets/images/logo/bri.png'
import mandiri from '../../assets/images/logo/mandiri.png'

import ovo from '../../assets/images/logo/OVO.svg'
import dana from '../../assets/images/logo/DANA.svg'
import gopay from '../../assets/images/logo/Gopay.svg'
import shopeepay from '../../assets/images/logo/Shopee.svg'

export const optionPaymentVA = [
  {
    name: 'BCA Virtual Account',
    image: bca
  },
  {
    name: 'Mandiri Virtual Account',
    image: mandiri
  },
  {
    name: 'BRI Virtual Account',
    image: bri
  }
]
export const optionPaymentBank = [
  {
    id: "ID_BCA",
    name: 'BCA One Click',
    identifierName: "Nomor Rekening BCA",
    image: bca
  },
  {
    id: "ID_MANDIRI",
    name: 'Mandiri Debit',
    identifierName: "Nomor Rekening Mandiri",
    image: mandiri
  },
  {
    id: "ID_BRI",
    name: 'BRI',
    identifierName: "Nomor Rekening BRI",
    image: bri
  }
]

export const optionPaymentDigital = [
  {
    name: 'Gopay',
    id: 'ID_GOPAY',
    identifierName: "Nomor Telepon Gopay",
    image: gopay
  },
  {
    name: 'OVO',
    id: 'ID_OVO',
    identifierName: "Nomor Telepon OVO",
    image: ovo
  },
  {
    name: 'DANA',
    id: 'ID_DANA',
    identifierName: "Nomor Telepon DANA",
    image: dana
  },
  {
    name: 'ShopeePay',
    id: 'ID_SHOPEEPAY',
    identifierName: "Nomor Telepon ShopeePay",
    image: shopeepay
  }
]