import React from 'react'
import PlusIcon from './PlusIcon'

export default function CircleButton(props) {
  const {hidden, setHidden, children} = props
  return (
    <button className="bg-primary-500 hover:bg-primary-600 duration-200 py-4 px-4 rounded-full text-black" type="button" onClick={() => setHidden(!hidden)} >
      {
        children === undefined ?
        <PlusIcon/>
        :
        children
      }
    </button> 
  )
}
