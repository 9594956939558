
const IconCalendarWeek = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className="size-4"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#000"
      d="M4 .667h1.333V2h5.334V.667H12V2h.667A1.333 1.333 0 0 1 14 3.333v9.334c0 .74-.593 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 12.666V3.333C2 2.593 2.593 2 3.333 2H4zm-.667 4.666v7.333h9.334V5.333zm1.334 1.334h6.666V8H4.667z"
    ></path>
  </svg>
  )
}
export default IconCalendarWeek
