
const IconFile = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className="size-4"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#0F0F05"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.667 1.334H4a1.333 1.333 0 0 0-1.333 1.333v10.667A1.333 1.333 0 0 0 4 14.667h8a1.333 1.333 0 0 0 1.333-1.333V6z"
    ></path>
    <path
      stroke="#0F0F05"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.667 1.334V6h4.666"
    ></path>
    </svg>
  )
}

export default IconFile
