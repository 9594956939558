import axios from "axios";

export const createKas = async (req) => {
    return axios.post(`${process.env.REACT_APP_API_URI}kas?communityID=${req.communityId}`, req.data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
    .then(response => {
      return response.data;
    })
  }
export const updateKas = async (req) => {
    return axios.put(`${process.env.REACT_APP_API_URI}kas/${req.kasId}`, req.data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
    .then(response => {
      return response.data;
    })
  }
export const getAllKasByCommunityId = async (communityId) => {
    return axios.get(`${process.env.REACT_APP_API_URI}kas/community/${communityId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
    .then(response => {
      return response.data;
    })
  }
export const deleteKasById = async (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URI}kas/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
    .then(response => {
      return response.data;
    })
  }
export const getKasById = async (id) => {
    return axios.get(`${process.env.REACT_APP_API_URI}kas/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
    .then(response => {
      return response.data;
    })
  }
export const updateKasPrimaryStatus = async (req) => {
    return axios.patch(`${process.env.REACT_APP_API_URI}kas/${req.kasId}`, req.data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
    })
    .then(response => {
      return response.data;
    })
  }
export const getTotalKasByCommunityId = async (communityId) => {
  return axios.get(`${process.env.REACT_APP_API_URI}kas/community/${communityId}/total`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}
export const getMembersKasPaymentStatusByKasId = async (kasId) => {
  return axios.get(`${process.env.REACT_APP_API_URI}kas/${kasId}/status`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}

export const postKasTransaction = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URI}transaction/create?type=kas`, data, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}
export const getKasTransactionHistory = async (req) => {
  // adding search params inspired by https://stackoverflow.com/questions/486896/adding-a-parameter-to-the-url-with-javascript#answer-44160941
  const url = new URL(`${process.env.REACT_APP_API_URI}transaction/history/kas/community/${req.communityId}`);
  for (const [key, value] of Object.entries(req.searchParams)) {
    url.searchParams.append(key, value);
  }
  return axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}
export const getKasChartData = async (req) => {
  // adding search params inspired by https://stackoverflow.com/questions/486896/adding-a-parameter-to-the-url-with-javascript#answer-44160941
  const url = new URL(`${process.env.REACT_APP_API_URI}ledgers/reference/${req.communityId}`);
  for (const [key, value] of Object.entries(req.searchParams)) {
    url.searchParams.append(key, value);
  }
  return axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}
export const payOutKas = async (req) => {
  return axios.post(`${process.env.REACT_APP_API_URI}kas/${req.kasId}/payout`, req.data, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}