import React from 'react'
import { Link } from 'react-router-dom';

export default function BackNavigation(props) {
  const {text, to, toggle = false, value = null, setValue = null, onClick = null} = props
  return (
    <div className='flex justify-between'>
      <Link to={to} onClick={onClick} className='flex space-x-3 items-center group'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
        <div className='font-semibold border-b border-transparent group-hover:border-black duration-200'>{text}</div>
      </Link>
      {
        toggle ?
        <button type="button" onClick={() => setValue(!value)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
          </svg>
        </button>
        : 
        ''
      }
    </div>
  )
}
