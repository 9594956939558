import axios from 'axios'

export const postTransaction = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URI}transaction/create?type=activity`, data, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}

export const getTransaction = async (data) => {
  return axios.get(`${process.env.REACT_APP_API_URI}transaction/history?days=${data.days ? data.days : ''}&type=${data.type ? data.type : ''}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}

export const verifyTransaction = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URI}transaction/verify?invoice-id=${data.id}`, data, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}

export const payoutTransaction = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URI}balance/payout`, data, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}

export const getTransactionByItemID = async (itemID) => {
  return axios.get(`${process.env.REACT_APP_API_URI}transaction/history/${itemID}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}