
const IconWallet = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className="size-5"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#000"
      strokeWidth="1.5"
      d="M15.833 16.667H4.167A1.667 1.667 0 0 1 2.5 15V7.5a1.667 1.667 0 0 1 1.667-1.666h11.666A1.666 1.666 0 0 1 17.5 7.5V15a1.667 1.667 0 0 1-1.667 1.667Z"
    ></path>
    <path
      fill="#000"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.75 11.667a.417.417 0 1 1 0-.834.417.417 0 0 1 0 .834"
    ></path>
    <path
      stroke="#000"
      strokeWidth="1.5"
      d="M15 5.833V4.67a1.666 1.666 0 0 0-2.096-1.61L3.738 5.503A1.67 1.67 0 0 0 2.5 7.114V7.5"
    ></path>
  </svg>
  )
}

export default IconWallet
