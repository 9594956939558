import React from 'react'
import IconLoading from '../atoms/IconLoading'

function LoadingButton(props) {
  const {type, onClick, isPending, children} = props
  return (
    <button type={type} onClick={onClick} className={`w-full py-2 md:py-3 px-2 md:px-4 text-center rounded-md text-base font-semibold duration-200` + ` ${isPending ? 'bg-black-200' : 'bg-primary-500'}`} disabled={isPending}>
        <div className='flex justify-center space-x-2 items-center'>
          {
            isPending ?
              <IconLoading/>
            : ''
          }
          <span>
          {isPending ? 'Loading...' : children}
          </span>
        </div>
    </button>
  )
}

export default LoadingButton