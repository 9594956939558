import React, { useState } from 'react'
import LabelBetween from '../molecules/LabelBetween'
import Label from '../atoms/Label'
import CurrencyInput from 'react-currency-input-field'

export default function InputText(props) {
  const { children, name, text, placeholder, type, value, setValue, isCurrency=false, isRequired=true } = props
  const [isHidden, setIsHidden] = useState(true)
  return (
    <div className="space-y-2">
      <LabelBetween>
        <Label htmlFor={name} text={text} />
        {children}
      </LabelBetween>
      {
        isCurrency ?
          <CurrencyInput
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          decimalsLimit={2}
          decimalSeparator="," groupSeparator="."
          allowNegativeValue={false}
          prefix='Rp'
          onValueChange={(value, name, values) => setValue(value)}
          className="w-full border border-black rounded-xl px-3 py-4" 
        />
      :
      <div className="relative">
        <input type={isHidden ? type : 'text'} id={name} name={name} value={value} onChange={(e) => setValue(e.target.value)} className="w-full border border-black rounded-xl px-3 py-4" placeholder={placeholder} required={isRequired} />
        {
          type === 'password' ?
          <button type="button" onClick={() => setIsHidden(!isHidden)} className="absolute top-0 end-0 px-3 py-5 rounded-e-md">
            <svg className="flex-shrink-0 size-4 text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path className={`${isHidden ? 'block' : 'hidden'}`} d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
              <path className={`${isHidden ? 'block' : 'hidden'}`} d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
              <path className={`${isHidden ? 'block' : 'hidden'}`} d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
              <line className={`${isHidden ? 'block' : 'hidden'}`} x1="2" x2="22" y1="2" y2="22"></line>
              <path className={`${!isHidden ? 'block' : ''}`} d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
              <circle className={`${!isHidden ? 'block' : ''}`} cx="12" cy="12" r="3"></circle>
            </svg>
          </button>
        : ''
        }
      </div>
      }
    </div>
  )
}
