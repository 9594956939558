import React from 'react'

export default function Button(props) {
  const { children, onClick, warna = 'putih', y=3,x=4 } = props
  return (
    <button onClick={onClick} type="button" className={`w-full block py-2 md:py-${y} px-1 md:px-${x} text-center border rounded-md text-base font-semibold flex justify-center space-x-1 md:space-x-2 duration-300 ${warna === 'putih' ? ' bg-transparent border border-black-500 hover:bg-primary-200 hover:border-transparent' : ' bg-primary-500 hover:bg-primary-600'}`}>
      {children}
    </button>
  )
}
