import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useToken from '../hooks/useToken';

function ScreenContainer(props) {
  const {children} = props
  
  const navigate = useNavigate()

  const {token} = useToken() 
  
  
  useEffect(() => {
    if(!token || token === null){
      toast.error('Silahkan login terlebih dahulu')
      navigate('/login')
    }
    
  }, [])
  
  return (
    <div className='font-inter bg-gray-50'>
      <div className="flex justify-center min-h-screen">
        <div className="w-full md:w-[480px] bg-white relative">
            {children}
        </div>
      </div>
    </div>
  )
}

export default ScreenContainer