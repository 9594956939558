import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../services/profile.services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getMemberByCommunityIDService } from "../../services/member.services";
import ScreenContainer from "../../components/templates/ScreenContainer";
import BackNavigation from "../../components/molecules/BackNavigation";
import PulseLoadingPost from "../../components/atoms/PulseLoadingPost";
import ManagementAdminPOV from "./ManagementAdminPOV";
import ManagementMemberPOV from "./ManagementMemberPOV";
import Navigation from "../../components/organisms/Navigation";
import { getTransactionByItemID } from "../../services/transaction.services";
import IconLoading from "../../components/atoms/IconLoading";

const ADMIN = "admin";
const MEMBER = "member";

const PAYMENT_TYPE = "payment"
const SUCCESS_STATUS = "success"
const PAID = "paid"
const Management = () => {
  const [searchParams, _] = useSearchParams();
  const { communityId } = useParams();
  const navigate = useNavigate()
  const { data: userResp, isPending: isPendingUser } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });
  const { data: membersResp, isPending: isPendingMembers } = useQuery({
    queryKey: ["members", communityId],
    queryFn: () => getMemberByCommunityIDService({ id: communityId }),
  });
  const getUserRole = () => {
    if (userResp && membersResp) {
      return membersResp?.members.find((member) => member.user._id === userResp?.user._id)?.role 
    }
  }
  const isAdmin = () => {
    const role = getUserRole();
    if (role) {
      return role === ADMIN;
    }
    return false;
  }

  // handle payment redirect
  const itemID = searchParams.get("itemID");
  const type = searchParams.get("type");
  const status = searchParams.get("status");
  const {data: transactionResp, isPending: isPendingTransaction} = useQuery({
    queryKey: ["payment", itemID],
    queryFn: () => getTransactionByItemID(itemID),
    enabled: !!itemID,
  });
  if (itemID && (isPendingTransaction || isPendingUser)) {
    return (
      <ScreenContainer>
        <div className="h-[90vh] overflow-y-auto">
          <div className="h-full w-full">
            <div className="flex h-full justify-center items-center w-full flex-col">
              <IconLoading />
              <p className="text-center text-md font-semibold text-black-500">Sedang melakukan pengecekan transaksi...</p>
            </div>
          </div>
        </div>
      </ScreenContainer>
    )
  }
  const userTransaction = transactionResp?.data.find((transaction) => transaction.user_id === userResp?.user._id)
  if (userTransaction && userTransaction.payment_status === PAID) {
    if (type === PAYMENT_TYPE && status === SUCCESS_STATUS) {
      navigate(`/kas/${communityId}/bayar/sukses`, { state:  {message: "Pembayaran Kas Berhasil"}})
      return
    }
  }

  if (isPendingUser || isPendingMembers) {
    return (
      <ScreenContainer>
        <div className="h-[90vh] overflow-y-auto">
          <div className="fixed top-0 w-full md:w-[480px] h-14 z-10 bg-white p-4">
            <BackNavigation
              text="Detail Komunitas"
              to={`/komunitas/${communityId}`}
            />
          </div>
          <div className="h-14 p-1"></div>
          <div className="p-4 m-4 bg-white rounded-lg shadow-md">
            <div
              className={`text-center text-sm text-black-500 h-60 mt-1 rounded-lg bg-black-100 animate-pulse`}
            ></div>
            <div
              className={`text-center text-sm text-black-500 h-24 mt-3 rounded-lg bg-black-100 animate-pulse`}
            ></div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <PulseLoadingPost size={10} />
              <PulseLoadingPost size={10} />
            </div>
          </div>
          <div className="w-1/2 m-4 my-8">
            <PulseLoadingPost size={10} />
          </div>
          <div className="m-4">
            <div className="flex justify-center items-centermy-4 w-full flex-col">
              <PulseLoadingPost size={28} />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <PulseLoadingPost size={32} />
              <PulseLoadingPost size={32} />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <PulseLoadingPost size={32} />
              <PulseLoadingPost size={32} />
            </div>
          </div>
        </div>
        <div className="px-4 pt-4 pb-10"></div>
        <Navigation />
      </ScreenContainer>
    );
  }
  if (isAdmin()) {
    return <ManagementAdminPOV userRole={getUserRole()} />
  }
  return <ManagementMemberPOV userRole={getUserRole()} />
};
export default Management;
