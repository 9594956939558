const IconUser = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      {...props}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 14.24v-1.333a2.666 2.666 0 0 0-2.666-2.666H5.333a2.667 2.667 0 0 0-2.666 2.666v1.334M8 7.574a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333"
      ></path>
    </svg>
  );
};

export default IconUser;
