
const IconCalendarMonth = (props) => {
  return (
    <svg
    className="w-4 h-4"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.33333 9.33317H4.66667V10.6665H9.33333M12.6667 12.6665H3.33333V5.33317H12.6667M12.6667 1.99984H12V0.666504H10.6667V1.99984H5.33333V0.666504H4V1.99984H3.33333C2.97971 1.99984 2.64057 2.14031 2.39052 2.39036C2.14048 2.64041 2 2.97955 2 3.33317V12.6665C2 13.0201 2.14048 13.3593 2.39052 13.6093C2.64057 13.8594 2.97971 13.9998 3.33333 13.9998H12.6667C13.0203 13.9998 13.3594 13.8594 13.6095 13.6093C13.8595 13.3593 14 13.0201 14 12.6665V3.33317C14 2.97955 13.8595 2.64041 13.6095 2.39036C13.3594 2.14031 13.0203 1.99984 12.6667 1.99984ZM11.3333 6.6665H4.66667V7.99984H11.3333V6.6665Z"
      fill="black"
    />
  </svg>
  )
}

export default IconCalendarMonth
