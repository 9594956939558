import axios from "axios";

export const getUserChannels = async () => {
  return axios.get(`${process.env.REACT_APP_API_URI}channel-properties`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
  .then(response => {
    return response.data;
  })
}