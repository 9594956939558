import axios from "axios"

export const getMemberByCommunityIDService = (data) => {
  return axios.get(`${process.env.REACT_APP_API_URI}community/members/${data.id}?limit=${data.limit}&page=${data.page}`)
    .then(response => {
      return response.data;
    })
}

export const updateRole = (data) => {
  // community/role/members
  return axios.put(`${process.env.REACT_APP_API_URI}community/role/members?userID=${data.user_id}&communityID=${data.community_id}`, {
    role: data.role
  },{
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
    .then(response => {
      return response.data;
    })
}

export const kickMember = (data) => {
  // http://127.0.0.1:8000/api/v1/community/kick/members?userID&communityID
  return axios.delete(`${process.env.REACT_APP_API_URI}community/kick/members?userID=${data.user_id}&communityID=${data.community_id}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
    },
  })
    .then(response => {
      return response.data;
    })
}