export const rupiah = (number)=>{
  // return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number).replace(/(\.|,)00$/g, '');
  return `Rp${number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
}

export const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}