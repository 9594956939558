
const IconCreditCard = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
    {...props}
  >
    <g
      stroke="#0F0F05"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      clipPath="url(#clip0_279_2373)"
    >
      <path d="M14.5 2.907h-12c-.736 0-1.333.597-1.333 1.334v8c0 .736.597 1.333 1.333 1.333h12c.736 0 1.333-.597 1.333-1.333v-8c0-.737-.597-1.334-1.333-1.334M1.167 6.907h14.666"></path>
    </g>
    <defs>
      <clipPath id="clip0_279_2373">
        <path fill="#fff" d="M.5.24h16v16H.5z"></path>
      </clipPath>
    </defs>
  </svg>
  )
}

export default IconCreditCard
