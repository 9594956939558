import React from 'react'
import CloseIcon from '../atoms/CloseIcon'

export default function PopupOverlayDelete(props) {
  const {hidden, setHidden, children, setHiddenMenu} = props
  
  const handleOverlay = () => {
    setHidden(!hidden)
    setHiddenMenu(true)
  }

  return (
    <div>
      <div className={`absolute z-20 w-full top-1/2 `}>
            {/* overlay */}
            <div className={`fixed inset-0 w-full h-full duration-600 ${hidden ? 'opacity-100' : 'opacity-0 invisible'}  ease-in-out`}>
              <div className="flex justify-center min-h-screen">
                <div className="w-full md:w-[480px] bg-black/20">
                </div>
              </div>
            </div>
            {/* end overlay */}
            <div className={`absolute flex  w-full justify-center z-30 transform  ${hidden ? 'bottom-0 transition-all duration-600 opacity-100' : 'bottom-10 opacity-0 invisible'}  `}>
              <div className="w-11/12 bg-white p-5 rounded-md">
                {
                  setHiddenMenu !== undefined ?
                  <div className='flex justify-end'>
                    <button type='button' onClick={() => {handleOverlay()}}>
                      <CloseIcon/>
                    </button>
                  </div>
                  :
                  ''
                }
                <div>
                  <div className="space-y-3">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
