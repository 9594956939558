import BackNavigation from "../../components/molecules/BackNavigation";
import ScreenContainer from "../../components/templates/ScreenContainer";
import Navigation from "../../components/organisms/Navigation";
import PulseLoadingPost from "../../components/atoms/PulseLoadingPost";
import { useParams } from "react-router-dom";
import { getProfile } from "../../services/profile.services";
import { getMemberByCommunityIDService } from "../../services/member.services";
import DetailKasAdminPOV from "./DetailKasAdminPOV";
import DetailKasMemberPOV from "./DetailKasMemberPOV";
import { useQuery } from "@tanstack/react-query";

const ADMIN = "admin";
const MEMBER = "member";
const DetailKas = () => {
  const { communityId, id } = useParams();
  const { data: userResp, isPending: isPendingUser } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });
  const { data: membersResp, isPending: isPendingMembers } = useQuery({
    queryKey: ["members", communityId],
    queryFn: () => getMemberByCommunityIDService({ id: communityId }),
  });
  const getUserRole = () => {
    if (userResp && membersResp) {
      return membersResp?.members.find((member) => member.user._id === userResp?.user._id)?.role 
    }
  }
  const isAdmin = () => {
    const role = getUserRole();
    if (role) {
      return role === ADMIN;
    }
    return false;
  }
  if (isPendingUser || isPendingMembers) {
    return (
      <ScreenContainer>
        <div className="h-[90vh] overflow-y-auto">
          <div className="fixed top-0 w-full md:w-[480px] h-14 z-10 bg-white p-4">
            <BackNavigation text="Detail Komunitas" to={`/kas/${communityId}`} />
          </div>
          <div className="h-14"></div>
          <div className="p-4 w-full md:w-[480px]">
            <PulseLoadingPost />
          </div>
          <div className="p-4 w-1/2" >
            <PulseLoadingPost size={10} />
          </div>
          <div className="p-4 w-full" >
            <PulseLoadingPost size={16} />
            <PulseLoadingPost size={16} mt={3}/>
            <PulseLoadingPost size={16} mt={3}/>
            <PulseLoadingPost size={16} mt={3}/>
            <PulseLoadingPost size={16} mt={3}/>
          </div>
        </div>
        <div className="px-4 pt-4 pb-10"></div>
        <Navigation />
      </ScreenContainer>
    );
  }
  if (isAdmin()) {
    return <DetailKasAdminPOV user={userResp?.user} userRole={getUserRole()} />
  }
  return <DetailKasMemberPOV user={userResp?.user} userRole={getUserRole()} />
};

export default DetailKas;
