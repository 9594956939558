import { useEffect, useState } from "react";
import BackNavigation from "../../components/molecules/BackNavigation";
import Navigation from "../../components/organisms/Navigation";
import ScreenContainer from "../../components/templates/ScreenContainer";
import IconDown from "../../components/atoms/IconDown";
import IconCalendarWeek from "../../components/atoms/IconCalendarWeek";
import IconCalendarMonth from "../../components/atoms/IconCalendarMonth";
import Button from "../../components/atoms/Button";
import IconWallet from "../../components/atoms/IconWallet";
import PopupOverlay from "../../components/templates/PopupOverlay";
import IconFile from "../../components/atoms/IconFile";
import gopay from "../../assets/images/logo/Gopay.svg";
import qris from "../../assets/images/logo/QRIS.svg";
import dana from "../../assets/images/logo/DANA.svg";
import ovo from "../../assets/images/logo/OVO.svg";
import shopeepay from '../../assets/images/logo/Shopee.svg'
import bca from '../../assets/images/logo/bca.png'
import bri from '../../assets/images/logo/bri.png'
import mandiri from '../../assets/images/logo/mandiri.png'
import wallet from "../../assets/images/icon/wallet.svg";
import kasWallet from "../../assets/images/kasWallet.svg";
import kasWalletGrey from "../../assets/images/kasWalletGrey.svg";
import grafikCard from "../../assets/images/memphis/income_grafic_card_top_right.svg";
import primaryCard from "../../assets/images/memphis/primary_kas_top_right.svg";
import IconDownload from "../../components/atoms/IconDownload";
import IconRight from "../../components/atoms/IconRight";
import { useQuery } from "@tanstack/react-query";
import {
  getAllKasByCommunityId,
  getKasChartData,
  getKasTransactionHistory,
  getTotalKasByCommunityId,
} from "../../services/kas.services";
import PulseLoadingPost from "../../components/atoms/PulseLoadingPost";
import { useNavigate, useParams } from "react-router-dom";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../components/atoms/Toast";
import { rupiah } from "../../components/Utils/utils";
import { Chart } from "chart.js";
import { Bar } from "react-chartjs-2";
import IconCalender from "../../components/atoms/IconCalender";
import IconXCircle from "../../components/atoms/IconXCircle";

const DOMPET_KAS = "Dompet Kas";
const RIWAYAT = "Riwayat";

const QRIS = "QRIS";
const DANA = "Dana";
const OVO = "OVO";
const GOPAY = "Gopay";

const ID_GOPAY = "ID_GOPAY";
const ID_OVO = "ID_OVO";
const ID_DANA = "ID_DANA";
const ID_SHOPEEPAY = "ID_SHOPEEPAY";

const ID_BCA = "ID_BCA"
const ID_BRI = "ID_BRI"
const ID_MANDIRI = "ID_MANDIRI"

const CASHFLOW_IN = "Pemasukan";
const CASHFLOW_IN_TYPE = "in";
const CASHFLOW_OUT = "Pengeluaran";
const CASHFLOW_OUT_TYPE = "out";
const CASHFLOW_WITHDRAW_TYPE = "WITHDRAW";
const WEEK_FILTER_TYPE = "mingguan"
const MONTH_FILTER_TYPE = "bulanan"

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthNameToNum = (month) => {
  return MONTHS.indexOf(month) + 1;
};
const monthNumberRepr = (month) => {
  const monthNum = monthNameToNum(month);
  return monthNum < 10 ? `0${monthNum}` : `${monthNum}`;
}
const MonthYearPicker = ({
  value = "Jan, 2024",
  setValue,
  from = null,
  to = null,
}) => {
  const valueSplit = value?.split(", ");
  const [month, setMonth] = useState(
    (valueSplit?.length > 0 && valueSplit[0]) || "Jan"
  );
  const [year, setYear] = useState(
    (valueSplit?.length > 1 && valueSplit[1]) || "2024"
  );
  const months = MONTHS
  const years = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    "2041",
    "2042",
    "2043",
    "2044",
    "2045",
    "2046",
    "2047",
    "2048",
    "2049",
    "2050",
  ];

  const validYear = (year) => {
    return years.indexOf(year) >= 0;
  };
  const nextYear = () => {
    const next = parseInt(year) + 1;
    if (validYear(next.toString())) {
      setYear(next.toString());
    }
  };
  const prevYear = () => {
    const prev = parseInt(year) - 1;
    if (validYear(prev.toString())) {
      setYear(prev.toString());
    }
  };
  const isMonthInRange = (month) => {
    const monthNum = monthNameToNum(month);
    if (from && to) {
      const fromMonthNum = monthNameToNum(from.split(", ")[0]);
      const toMonthNum = monthNameToNum(to.split(", ")[0]);
      const fromYearNum = parseInt(from.split(", ")[1]);
      const toYearNum = parseInt(to.split(", ")[1]);
      const yearNum = parseInt(year);
      if (yearNum >= fromYearNum && yearNum <= toYearNum) {
        if (monthNum >= fromMonthNum && monthNum <= toMonthNum) {
          return true;
        } else if (yearNum === fromYearNum) {
          if (monthNum >= fromMonthNum) {
            return true;
          }
        } else if (yearNum === toYearNum) {
          if (monthNum <= toMonthNum) {
            return true;
          }
        }
      }
    } else if (from) {
      const fromMonthNum = monthNameToNum(from.split(", ")[0]);
      const fromYearNum = parseInt(from.split(", ")[1]);
      const yearNum = parseInt(year);
      if (yearNum >= fromYearNum) {
        if (monthNum >= fromMonthNum) {
          return true;
        }
      }
    } else if (to) {
      const toMonthNum = monthNameToNum(to.split(", ")[0]);
      const toYearNum = parseInt(to.split(", ")[1]);
      const yearNum = parseInt(year);
      if (yearNum === toYearNum) {
        return monthNum <= toMonthNum;
      }
      return yearNum < toYearNum;
    } else {
      return true;
    }
    return false;
  };
  const updateValue = () => {
    if (month && year) {
      if (isMonthInRange(month)) {
        setValue && setValue(`${month}, ${year}`);
      } else {
        toastWarning("Please select month in range");
      }
    }
  };
  useEffect(() => {
    updateValue();
  }, [month, year]);

  return (
    <div className="w-full p-4 border rounded-xl">
      {/* year picker */}
      <div className="py-2 text-md font-semibold pb-4 flex justify-between items-center">
        <select
          name=""
          id=""
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="appearance-auto bg-transparent border-none text-md font-semibold text-gray-500"
        >
          {years.map((_year, index) => (
            <option key={index} value={_year}>{_year}</option>
          ))}
        </select>
        <div className="flex items-center space-x-1 cursor-pointer">
          <div className="rotate-180" onClick={prevYear}>
            <IconRight />
          </div>
          <div onClick={nextYear}>
            <IconRight />
          </div>
        </div>
      </div>
      {/* month picker */}
      <div className="grid grid-cols-3 gap-2">
        {months.map((_month, index) => {
          const valid = isMonthInRange(_month);
          return (
            <div
              key={index}
              className={`rounded-lg w-full flex justify-center items-center p-1 ${
                _month === month
                  ? "bg-primary-500"
                  : "bg-white text-black border"
              } cursor-pointer hover:bg-primary-300 hover:text-white transition-all duration-100 ${
                valid ? "" : "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => valid && setMonth(_month)}
            >
              <p className="text-md font-semibold">{_month}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const exampleBarChartData = [
  {
    label: "Minggu 1",
    value: 20000,
  },
  {
    label: ["Minggu", "2"],
    value: 10000,
  },
  {
    label: "Minggu 3",
    value: 200000,
  },
  {
    label: "Minggu 4",
    value: 15000,
  },
  {
    label: "Minggu 5",
    value: 10000,
  },
  {
    label: ["Minggu", "6"],
    value: 300000,
  },
];
const BarChart = ({ data = exampleBarChartData, name = "Pemasukan" }) => {
  // if data is empty return container with empty data info
  if (data.length === 0) {
    return (
      <div className="flex justify-center items-center h-52 p-8">
        <div className="border-r border-b border-gray-300  w-full h-full flex justify-center items-center">
          <p className="text-gray-500 text-lg font-medium">Data belum tersedia</p>
        </div>
      </div>
    )
  }

  if (data.length < 5) {
    // add empty dummy data to fill the chart
    const dummyData = Array.from({ length: 5 - data.length }, (_, i) => ({
      label: "",
      value: 0,
    }));
    data = [...dummyData, ...data];
  }
  const labels = data.map((item) => item.label);
  const values = data.map((item) => item.value);
  const longestLabel = Math.max(
    ...labels.map((label) => (Array.isArray(label) ? label.length : 1))
  );
  const minValue = 10_000;
  let maxValue = Math.max(...values);
  maxValue = maxValue < 50_000? 50_000 : maxValue;
  const properStepSize = () => {
    if (maxValue > 200_000) {
      return 50_000;
    }
    const rawStepSize = Math.round(maxValue / 5)
    const thousandReminder = rawStepSize % 1000
    return thousandReminder === 0? rawStepSize : rawStepSize - thousandReminder
  }
  
  return (
    <div className="flex justify-start flex-row-reverse items-center">
      {/* Custom y-Axis */}
      <div className="w-[40px] overflow-x-hidden pb-[10.7px]">
        <div className="h-[232px] w-[84px] -translate-x-12">
          <Bar
            data={{
              labels: [Array.from({ length: longestLabel }, (_, i) => "")],
              datasets: [
                {
                  data: [maxValue],
                  maxBarThickness: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  // min: 10000,
                  suggestedMin: minValue,
                  suggestedMax: maxValue,
                  ticks: {
                    stepSize: properStepSize(),
                    callback: function (value) {
                      return value > 0
                        ? `${value.toString().slice(0, -3)}rb`
                        : "";
                    },
                  },
                  position: "right",
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
      {/* Custom bar chart content */}
      <div className="overflow-x-scroll scrollbar-x-sm pb-2">
        <div
          className={`h-[232px]`}
          style={{
            width: `${70 * labels.length}px`,
          }}
        >
          <Bar
            data={{
              labels: labels,
              datasets: [
                {
                  label: name,
                  data: values,
                  backgroundColor: ["#6DE35F"],
                  borderRadius: 5,
                  // minBarLength: 32,
                  barPercentage: 0.5,
                  categoryPercentage: 1.0,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: function (tooltipItems) {
                      const tooltipItem = tooltipItems[0];
                      const labels = tooltipItem.label.split(",");
                      return labels.join(" ")
                    },
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  // display: false,
                  // min: 10000,
                  suggestedMin: minValue,
                  suggestedMax: maxValue,
                  ticks: {
                    stepSize: properStepSize(),
                    callback: function (_) {
                      return "";
                    },
                  },
                  position: "right",
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
const HISTORY_WEEK_FILTER = "history_week";
const HISTORY_MONTH_FILTER = "history_month";
const HISTORY_FROM_DATE_PICKER = "history_date_from";
const HISTORY_TO_DATE_PICKER = "history_date_to";
const GRAPH_FROM_DATE_FILTER = "graph_date_from";
const GRAPH_TO_DATE_FILTER = "graph_date_to";
const ManagementMemberPOV = (props) => {
  const { userRole } = props;
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(DOMPET_KAS);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [walletSelectOpen, setWalletSelectOpen] = useState(false);
  const [selectedWalletHistory, setSelectedWalletHistory] = useState(null);
  const [filterHistoryDateFrom, setFilterHistoryDateFrom] = useState(null);
  const [filterHistoryDateTo, setFilterHistoryDateTo] = useState(null);
  const [selectedHistoryDateFilter, setSelectedHistoryDateFilter] =
    useState(null);
  const [selectedHistoryDatePicker, setSelectedHistoryDatePicker] =
    useState(null);
  const [graphDateFilterOpen, setGraphDateFilterOpen] = useState(false);
  const [filterGraphDateFrom, setFilterGraphDateFrom] = useState(null);
  const [filterGraphDateTo, setFilterGraphDateTo] = useState(null);
  const [selectedGraphDateFilter, setSelectedGraphDateFilter] = useState(null);
  const [chartType, setChartType] = useState(CASHFLOW_IN_TYPE);
  const [chartFilterType, setChartFilterType] = useState(WEEK_FILTER_TYPE)
  const [chartReqParams, setChartReqParams] = useState({
    filter_type : WEEK_FILTER_TYPE,
    cashflow: CASHFLOW_IN_TYPE
  })
  const { communityId } = useParams();
  const {
    data: dataKas,
    error: errorKas,
    isPending: isPendingKas,
    isError: isErrorKas,
    refetch: refetchKas,
  } = useQuery({
    queryKey: ["kas", communityId],
    queryFn: () => getAllKasByCommunityId(communityId),
  });
  const {
    data: dataTotalKas,
    error: errorTotalKas,
    isPending: isPendingTotalKas,
  } = useQuery({
    queryKey: ["kas-total", communityId],
    queryFn: () => getTotalKasByCommunityId(communityId),
  });
  const resetHistoryFilter = () => {
    if (dataKas) {
      if (dataKas.kas.length > 0) {
        setSelectedWalletHistory(dataKas.kas[0]);
      }
    }
    setSelectedHistoryDateFilter(HISTORY_WEEK_FILTER)
    setSelectedHistoryDatePicker(null)
    setFilterHistoryDateFrom(null)
    setFilterHistoryDateTo(null)
  }
  useEffect(() => {
    resetHistoryFilter()
  }, [dataKas]);
  const getMonth = (date) => {
    if (date) {
      const month = date.split(", ")[0]
      return monthNumberRepr(month)
    }
  }
  const getYear = (date) => {
    if (date) {
      const year = date.split(", ")[1]
      return year
    }
  }
  const getHistoryFilterType = (histoy_filter) => {
    if (histoy_filter === HISTORY_WEEK_FILTER) {
      return WEEK_FILTER_TYPE
    } else if (histoy_filter === HISTORY_MONTH_FILTER) {
      return MONTH_FILTER_TYPE
    }
  }
  const {
    data: dataTransactionHistory,
    error: errorTransactionHistory,
    isPending: isPendingTransactionHistory,
    refetch: refetchTransactionHistory,
  } = useQuery({
    queryKey: [
      "kas-transaction-history", 
      selectedWalletHistory?._id,
      selectedHistoryDateFilter,
      filterHistoryDateFrom,
      filterHistoryDateTo,
    ],
    queryFn: () =>
      getKasTransactionHistory({
        communityId,
        searchParams: {
          kas_id: selectedWalletHistory?._id,
          filter_type: getHistoryFilterType(selectedHistoryDateFilter),
          from_month: getMonth(filterHistoryDateFrom),
          from_year: getYear(filterHistoryDateFrom),
          to_month: getMonth(filterHistoryDateTo),
          to_year: getYear(filterHistoryDateTo),
        }
      }),
    enabled: !!selectedWalletHistory && !dateFilterOpen && !walletSelectOpen,
  });
  const handleApplySelectedWalletHistory = () => {
    if (selectedWalletHistory) {
      // setSelectedHistoryDatePicker(null);
      // setSelectedHistoryDateFilter(null);
      // setFilterHistoryDateFrom(null);
      // setFilterHistoryDateTo(null);
      refetchTransactionHistory();
      setWalletSelectOpen(false);
    }
  };
  const handleApplyHistoryDateFilter = () => {
    refetchTransactionHistory();
    setDateFilterOpen(false);
  };
  const handleMonthGraphFilterClicked = () => {
    setGraphDateFilterOpen(true);
    setChartFilterType(MONTH_FILTER_TYPE);
  }

  const {data: chartData, error: chartError, isPending: chartIsPending, refetch: refetchChartData} = useQuery({
    queryKey: [
      "kas-cashflow",
      communityId,
      chartType,
      chartFilterType,
      filterGraphDateFrom,
      filterGraphDateTo,
    ],
    queryFn: () =>
      getKasChartData({
        communityId,
        searchParams: {
          filter_type: chartFilterType,
          from_month: getMonth(filterGraphDateFrom),
          from_year: getYear(filterGraphDateFrom),
          to_month: getMonth(filterGraphDateTo),
          to_year: getYear(filterGraphDateTo),
          cashflow: chartType
        },
      }),
    enabled: !graphDateFilterOpen,
  });
  const handleApplyGraphDateFilter = () => {
    setGraphDateFilterOpen(false);
    refetchChartData();
  }
  const getMinYearRepr = (year) => {
    if (year) {
      return year.toString().slice(2, 4)
    }
  }
  const graphDateFilterRepr = () => {
    const fromMonth = getMonth(filterGraphDateFrom)
    const toMonth = getMonth(filterGraphDateTo)
    const fromYear = getMinYearRepr(getYear(filterGraphDateFrom))
    const toYear = getMinYearRepr(getYear(filterGraphDateTo))
    if (filterGraphDateFrom && filterGraphDateTo) {
      return `${fromMonth}/${fromYear} - ${toMonth}/${toYear}`
    } else if (filterGraphDateFrom) {
      return `> ${fromMonth}/${fromYear}`
    } else if (filterGraphDateTo) {
      return `< ${toMonth}/${toYear}`
    } else {
      return "Bulanan"
    }
  }
  const historyDateFilterRepr = () => {
    if (selectedHistoryDateFilter === HISTORY_WEEK_FILTER) {
      return "Mingguan"
    } else if (selectedHistoryDateFilter === HISTORY_MONTH_FILTER) {
      const fromMonth = getMonth(filterHistoryDateFrom)
      const toMonth = getMonth(filterHistoryDateTo)
      const fromYear = getMinYearRepr(getYear(filterHistoryDateFrom))
      const toYear = getMinYearRepr(getYear(filterHistoryDateTo))
      if (filterHistoryDateFrom && filterHistoryDateTo) {
        return `${fromMonth}/${fromYear} - ${toMonth}/${toYear}`
      } else if (filterHistoryDateFrom) {
        return `> ${fromMonth}/${fromYear}`
      } else if (filterHistoryDateTo) {
        return `< ${toMonth}/${toYear}`
      } else {
        return "Bulanan"
      }
    }
    return "Tanggal"
  }
  return (
    <ScreenContainer>
      <div className="h-[90vh] overflow-y-auto">
        <div className="fixed top-0 w-full md:w-[480px] h-14 z-10 bg-white p-4">
          <BackNavigation
            text="Detail Komunitas"
            to={`/komunitas/${communityId}`}
          />
        </div>
        <div className="h-14 p-1"></div>
        <div className="relative m-4 p-4 shadow-md rounded-lg bg-white overflow-hidden">
          {/* card grafict and total content */}
          <div className="absolute top-0 right-0">
            <img src={grafikCard} alt="memphis"   />
          </div>
          <div className="">
            <div className="">
              {/* card grafict */}
              <div className="bg-white ">
                {/* <div className="flex justify-self-start items-center mb-2">
                  <h1 className="text-xl font-bold">Grafik Pemasukan</h1>
                  <div className="mx-1">
                    <IconDown w={2.8} />
                  </div>
                </div> */}
                <div className="flex justify-self-start items-center mb-2">
                  <select
                    name="graphOption"
                    id="graphOption"
                    className="appearance-none bg-transparent border-none text-lg font-semibold focus:outline-none"
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                  >
                    <option value={CASHFLOW_IN_TYPE}>Grafik Pemasukan</option>
                    {/* <option value={CASHFLOW_OUT_TYPE}>Grafik Pengeluaran</option> */}
                  </select>
                  {/* <h1 className="text-xl font-bold">Grafik Pemasukan</h1> */}
                  <label htmlFor="graphOption" className="mx-1">
                    <IconDown w={2.8} />
                  </label>
                </div>
                {/* filter mingguan dan bulanan */}
                <div className="flex justify-self-start mb-4">
                  <div 
                    className={`flex items-center p-1 px-2 border mx-2 border-gray-300 rounded-md cursor-pointer ${
                      chartFilterType === WEEK_FILTER_TYPE ? "bg-primary-500" : "bg-white"
                    }`}
                    onClick={() => setChartFilterType(WEEK_FILTER_TYPE)}
                  >
                    <IconCalendarWeek strokeWidth={1.5} />
                    <span className="text-sm ms-1">Mingguan</span>{" "}
                  </div>
                  <div
                    className={`flex items-center p-1 px-2 border mx-2 border-gray-300 rounded-md cursor-pointer ${
                      chartFilterType === MONTH_FILTER_TYPE ? "bg-primary-500" : "bg-white"
                    }`}
                    onClick={() => handleMonthGraphFilterClicked()}
                  >
                    <IconCalendarMonth strokeWidth={1.5} />
                    <span className="text-sm ms-1">{graphDateFilterRepr()}</span>
                    <div className="">
                      <IconDown w={1} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full my-2">
                {/* <p className="text-sm">
                  Grafik kas yang dapat dilihat oleh admin
                </p> */}
                {chartIsPending ? (
                  <div>
                    <PulseLoadingPost size={32} />
                  </div>
                ) : chartError ? (
                  <div>
                    <p className="text-red-500 text-lg font-bold">
                      {chartError.message}
                    </p>
                  </div>
                ) : (
                <BarChart data={
                  chartData?.data.filter((kas) => !!kas.kas_details /** filter out empty kas details */)
                    .map((kas) => ({
                    label: kas.kas_details.name.split(" "),
                    value: parseInt(kas.kas_details.amount),
                  }))
                }
                name={chartType == CASHFLOW_IN_TYPE ? CASHFLOW_IN : CASHFLOW_OUT} />
                )}
              </div>
            </div>
            <hr />
            <div className="bg-white rounded-lg p-4">
              {/* card total content */}
              <div className="flex justify-between">
                <div className="w-1/2">
                  <h1 className="text-md font-semibold">Total Saldo Kas</h1>
                  {isPendingTotalKas ? (
                    <>
                      <PulseLoadingPost size={7} />
                      <PulseLoadingPost size={5} mt={1} />
                    </>
                  ) : (
                    <>
                      <p className="text-3xl font-bold">
                        {rupiah(dataTotalKas?.data.total_kas)}
                      </p>
                      {/* NEXT FEATURE */}
                      {/* <div className="flex justify-start items-center text-sm m-1 border rounded-md border-gray-300 p-1">
                        <span className="mx-2">
                          <IconDownload strokeWidth={1.5} />
                        </span>
                        <p>Download Laporan</p>
                      </div> */}
                    </>
                  )}
                </div>
                <div>
                  <span>
                    <img src={kasWallet} alt=""   />
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div>
              {/* buttons */}
              <div className="grid gap-3 grid-cols-2 py-4">
                <Button
                  y={2}
                  warna={activeMenu === DOMPET_KAS ? "primary" : "putih"}
                  onClick={() => {
                    setActiveMenu(DOMPET_KAS);
                  }}
                >
                  <div className="flex justify-center items-center">
                    <IconWallet />
                    <span className="ml-1">{DOMPET_KAS}</span>
                  </div>
                </Button>
                <Button
                  y={2}
                  warna={activeMenu === RIWAYAT ? "primary" : "putih"}
                  onClick={() => {
                    setActiveMenu(RIWAYAT);
                  }}
                >
                  <div className="flex justify-center items-center">
                    <IconFile />
                    <span className="ml-1">{RIWAYAT}</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        {activeMenu === DOMPET_KAS ? (
          <>
            <div className="px-4 pt-4 pb-10">
              {/* Dompet kas menu */}
              <div className="flex justify-start text-3xl font-bold py-2 ">
                {/* Dompet kas Title */}
                <p>Dompet Kas</p>
              </div>
              {isPendingKas ? (
                <div className="my-4">
                  <div className="flex justify-center items-centermy-4 w-full flex-col">
                    <PulseLoadingPost size={28} />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <PulseLoadingPost size={32} />
                    <PulseLoadingPost size={32} />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <PulseLoadingPost size={32} />
                    <PulseLoadingPost size={32} />
                  </div>
                </div>
              ) : dataKas.kas.length > 0 ? (
                <div>
                  {" "}
                  {/* Dompet kas card content */}{" "}
                  {/* Dompet kas card 1 (utama) */}
                  {dataKas.kas
                    .filter((kas) => kas.is_primary)
                    .map((kas, i) => {
                      return (
                        <div
                          key={i}
                          className="relative bg-white rounded-lg border-2 border-primary-500 p-5 my-4"
                        >
                          <div className="absolute top-0 right-0 -z-0">
                            <img src={primaryCard} alt="memphis"   />
                          </div>
                          <div className="bg-transparent w-full h-full">
                            <div
                              className="flex justify-start items-center w-full"
                              onClick={() =>
                                navigate(
                                  `/kas/${communityId}/detail/${kas._id}`
                                )
                              }
                            >
                              <img src={wallet} alt="wallet-icon" width={60} />
                              <div className="ml-5">
                                <p className="text-lg font-semibold">
                                  {kas.name}
                                </p>
                                <p className="text-2xl font-bold">
                                  {rupiah(kas.price_per_member)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* grid 2 column */}
                  <div className="grid grid-cols-2 gap-4">
                    {dataKas.kas
                      .filter((kas) => !kas.is_primary)
                      .map((kas, i) => {
                        return (
                          <div
                            key={i}
                            className="bg-white rounded-lg border-2 p-4"
                          >
                            <div
                              className="w-full h-full"
                              onClick={() =>
                                navigate(
                                  `/kas/${communityId}/detail/${kas._id}`
                                )
                              }
                            >
                              <div className="flex justify-between my-1">
                                <div>
                                  <img src={wallet} alt="wallet-icon" />
                                </div>
                              </div>
                              <div className="text-md font-semibold">
                                <p>{kas.name}</p>
                              </div>
                              <div className="text-lg font-bold">
                                <p>{rupiah(kas.price_per_member)}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <div>
                  {" "}
                  {/* If No Kas */}
                  <div className="flex justify-center items-center my-4 flex-col">
                    <div>
                      <img src={kasWalletGrey} alt="empty-kas-wallet" />
                    </div>
                    <div className="w-1/2">
                      <p className="text-center text-wrap text-gray-400 font-bold text-lg">
                        Kamu belum punya dompet kas
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="px-4 pt-4 pb-10">
            {/* Riwayat menu */}
            <div>
              {/* header */}
              <div className="flex justify-self-start">
                <h1 className="text-3xl font-bold mb-2">Riwayat Transaksi</h1>
              </div>
              <div className="flex justify-self-start mb-4 gap-x-3">
              {/* filter mingguan dan bulanan */}
                <div
                  className="flex items-center p-1 border border-gray-300 rounded-md font-semibold cursor-pointer"
                  onClick={(e) => setDateFilterOpen(true)}
                  >
                  <span className="text-sm mx-2">{historyDateFilterRepr()}</span>
                  <IconDown w={1} />
                </div>
                {/* filter Wallet */}
                <div
                  className="flex items-center p-1 border border-gray-300 rounded-md font-semibold cursor-pointer"
                  onClick={(e) => setWalletSelectOpen(true)}
                >
                  <span className="text-sm mx-2 max-w-28 text-ellipsis text-nowrap overflow-x-clip">{selectedWalletHistory?.name || "Dompet"}</span>
                  <IconDown w={1} />
                </div>
                <div
                  className="flex items-center p-1 border border-gray-300 rounded-md font-semibold cursor-pointer"
                  onClick={(e) => resetHistoryFilter()}
                >
                  <span className="text-sm mx-2">Reset</span>
                </div>
              </div>
            </div>
            <div>
              {/* card list */}
              {isPendingTransactionHistory ? (
                <div className="w-full p-1">
                  <PulseLoadingPost size={20} />
                  <PulseLoadingPost size={20} mt={3} />
                  <PulseLoadingPost size={20} mt={3} />
                </div>
              ) : dataTransactionHistory?.data.length > 0 ? (
                dataTransactionHistory?.data.map((item, i) => {
                  return (
                    <div className="flex my-1 bg-white justify-between shadow-sm">
                      <div className="flex justify-start py-1">
                        <div>
                          {/* profile picture */}
                          <div className="w-14 h-14 bg-gray-200 rounded-full flex justify-center items-center overflow-hidden">
                            <img
                              src={item.user.user_photo.image_url}
                              alt={item.user.nama_lengkap}
                            />
                          </div>
                        </div>
                        <div>
                          {/* profil name and date */}
                          <div className="flex justify-between h-full flex-col mx-2">
                            <div className="text-xl">
                              <p>{item.user.nama_lengkap}</p>
                            </div>
                            <div className="flex text-xs font-light">
                              <p>{item.transaction_date}</p>
                              <p className="mx-2">•</p>
                              <p>{item.transaction_time}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* amount and bill type */}
                        <div className="flex justify-between">
                          {item.cashflow === CASHFLOW_WITHDRAW_TYPE? (
                            <div className="text-xl font-bold text-[#0F0F05]">
                              - {rupiah(item.amount)}
                            </div>
                          ) : (
                            <div className="text-xl font-bold text-[#2FA336]">
                              {rupiah(item.amount)}
                            </div>
                          )}
                        </div>
                        {item.payment_type === QRIS ? ( // just for payment case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={qris} alt="icon qris" className="h-5 w-10 pe-2" />
                            </span>
                            <span>{QRIS}</span>
                          </div>
                        ) : item.payment_type === DANA || item.payment_type === ID_DANA ? ( // both payment and withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={dana} alt="icon dana" className="h-6 w-12 pe-2" />
                            </span>
                            <span>{DANA}</span>
                          </div>
                        ) : item.payment_type === OVO || item.payment_type === ID_OVO ? ( // both payment and withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={ovo} alt="icon ovo" />
                            </span>
                            <span>{OVO}</span>
                          </div>
                        ) : item.payment_type === GOPAY || item.payment_type === ID_GOPAY ? ( // both payment and withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={gopay} alt="icon gopay" />
                            </span>
                            <span>{GOPAY}</span>
                          </div>
                        ) : item.payment_type === ID_SHOPEEPAY ? ( // just for withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={shopeepay} alt="icon gopay" />
                            </span>
                            <span>ShopeePay</span>
                          </div>
                        ) : item.payment_type === ID_BCA ? ( // just for withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={bca} alt="icon bca" />
                            </span>
                            <span>BCA</span>
                          </div>
                        ) : item.payment_type === ID_MANDIRI ? ( // just for withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={mandiri} alt="icon mandiri" />
                            </span>
                            <span>Mandiri</span>
                          </div>
                        ) : item.payment_type === ID_BRI ? ( // just for withdraw case
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={bri} alt="icon bri" />
                            </span>
                            <span>BRI</span>
                          </div>
                        ) : ( // default set to Gopay
                          <div className="flex justify-end items-center">
                            <span>
                              <img src={gopay} alt="icon ovo" />
                            </span>
                            <span>Gopay</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center items-center my-4 flex-col">
                  <div>
                    <img src={kasWalletGrey} alt="empty-history" />
                  </div>
                  <div className="w-1/2">
                    <p className="text-center text-wrap text-gray-400 font-bold text-lg">
                      Riwayat transaksi kosong
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <PopupOverlay
        hidden={walletSelectOpen}
        setHidden={setWalletSelectOpen}
        title="Dompet Kas"
      >
        {/* Wallet Filter */}
        <div className="flex flex-col items-center">
          {dataKas?.kas.length > 0 ? (
            dataKas?.kas.map((kas, i) => (
              <div
                key={i}
                className="flex justify-between items-center my-3 w-full"
              >
                <p className="font-semibold text-lg">{kas.name}</p>
                <div
                  className={`h-5 w-5 border border-black-500 rounded-full flex justify-center items-center ${
                    kas._id === selectedWalletHistory?._id
                      ? "bg-primary-500"
                      : "bg-white"
                  }`}
                  onClick={() => setSelectedWalletHistory(kas)}
                ></div>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center my-4 flex-col">
              <div>
                <img src={kasWalletGrey} alt="empty-kas-wallet" />
              </div>
              <div className="w-1/2">
                <p className="text-center text-wrap text-gray-400 font-bold text-lg">
                  Kamu belum punya dompet kas
                </p>
              </div>
            </div>
          )}
          <div
            className="flex justify-between items-center my-3 w-full"
            onClick={() => handleApplySelectedWalletHistory()}
          >
            <Button warna="primary">
              <p className="font-semibold">Terapkan</p>
            </Button>
          </div>
        </div>
      </PopupOverlay>
      <PopupOverlay
        hidden={dateFilterOpen}
        setHidden={setDateFilterOpen}
        title="Tanggal Transaksi"
      >
        {/* Date Filter */}
        <div className="flex flex-col items-center">
          <div
            className="flex justify-between items-center my-3 w-full"
            onClick={() => setSelectedHistoryDateFilter(HISTORY_WEEK_FILTER)}
          >
            <div className="flex justify-between items-start flex-col">
              <p className="font-semibold text-lg my-1">Mingguan</p>
              <p className="text-sm font-medium my-1 text-gray-500">
                Riwayat 7 hari terakhir
              </p>
            </div>
            <div
              className={`h-5 w-5 border border-black-500 rounded-full flex justify-center items-center ${
                selectedHistoryDateFilter === HISTORY_WEEK_FILTER
                  ? "bg-primary-500"
                  : "bg-white"
              }`}
            ></div>
          </div>
          <div
            className="flex justify-between items-center my-3 w-full"
            onClick={() => setSelectedHistoryDateFilter(HISTORY_MONTH_FILTER)}
          >
            <div className="flex justify-between items-start flex-col">
              <p className="font-semibold text-lg my-1">Bulanan</p>
              <p className="text-sm font-medium my-1 text-gray-500">
                Riwayat sesuai bulan & tahun
              </p>
            </div>
            <div
              className={`h-5 w-5 border border-black-500 rounded-full flex justify-center items-center ${
                selectedHistoryDateFilter === HISTORY_MONTH_FILTER
                  ? "bg-primary-500"
                  : "bg-white"
              }`}
            ></div>
          </div>
          {selectedHistoryDateFilter === HISTORY_MONTH_FILTER && (
            <>
              <div className="grid grid-cols-2 gap-2 m-4 w-full space-x-2">
                {/* form */}
                <div
                  className="flex flex-col items-start space-y-2"
                  onClick={() =>
                    setSelectedHistoryDatePicker(HISTORY_FROM_DATE_PICKER)
                  }
                >
                  {/* <label className="font-semibold text-lg">Dari</label>
              <input
                type="date"
                className="w-full rounded-md border border-gray-300 p-2"
              /> */}
                  <p>Dari</p>
                  <div className="flex items-center w-full gap-x-2">
                    <div
                      className={`p-3 rounded-lg border  ${
                        selectedHistoryDatePicker === HISTORY_FROM_DATE_PICKER
                          ? "border-primary-500 border-2"
                          : "border-gray-300"
                      } w-full flex justify-between cursor-text`}
                    >
                      <p>{filterHistoryDateFrom || "Default"}</p>
                      <div>
                        <IconCalender />
                      </div>
                    </div>
                    {/* Reset button */}
                    <div onClick={() => setFilterHistoryDateFrom(null)} className="cursor-pointer" >
                      <IconXCircle />
                    </div>
                  </div>
                </div>
                {/* to */}
                <div
                  className="flex flex-col items-start space-y-2"
                  onClick={() =>
                    setSelectedHistoryDatePicker(HISTORY_TO_DATE_PICKER)
                  }
                >
                  {/* <label className="font-semibold text-lg">Sampai</label>
              <input
              type="date"
              className="w-full rounded-md border border-gray-300 p-2"
              /> */}
                  <p>Sampai</p>
                  <div className="flex items-center w-full gap-x-2">
                    <div
                      className={`p-3 rounded-lg border  ${
                        selectedHistoryDatePicker === HISTORY_TO_DATE_PICKER
                          ? "border-primary-500 border-2"
                          : "border-gray-300"
                      } w-full flex justify-between cursor-text`}
                    >
                      <p>{filterHistoryDateTo || "Default"}</p>
                      <div>
                        <IconCalender />
                      </div>
                    </div>
                    {/* Reset button */}
                    <div onClick={() => setFilterHistoryDateTo(null)} className="cursor-pointer" >
                      <IconXCircle />
                    </div>
                  </div>
                </div>
              </div>
              {selectedHistoryDatePicker === HISTORY_FROM_DATE_PICKER ? (
                <MonthYearPicker
                  value={filterHistoryDateFrom}
                  setValue={setFilterHistoryDateFrom}
                  to={filterHistoryDateTo}
                />
              ) : (
                <MonthYearPicker
                  value={filterHistoryDateTo}
                  setValue={setFilterHistoryDateTo}
                  from={filterHistoryDateFrom}
                />
              )}
            </>
          )}
          <div className="flex justify-between items-center my-3 w-full" onClick={() => handleApplyHistoryDateFilter()}>
            <Button warna="primary">
              <p className="font-semibold">Terapkan</p>
            </Button>
          </div>
        </div>
      </PopupOverlay>
      <PopupOverlay
        hidden={graphDateFilterOpen}
        setHidden={setGraphDateFilterOpen}
        title="Filter Bulanan"
      >
        {/* Date Filter */}
        <div className="flex flex-col items-center">
          <div className="grid grid-cols-2 gap-2 m-4 w-full space-x-2">
            {/* form */}
            <div
              className="flex flex-col items-start space-y-2"
              onClick={() => setSelectedGraphDateFilter(GRAPH_FROM_DATE_FILTER)}
            >
              {/* <label className="font-semibold text-lg">Dari</label>
              <input
                type="date"
                className="w-full rounded-md border border-gray-300 p-2"
              /> */}
              <p>Dari</p>
              <div className="flex items-center w-full gap-x-2">
                <div
                  className={`p-3 rounded-lg border  ${
                    selectedGraphDateFilter === GRAPH_FROM_DATE_FILTER
                      ? "border-primary-500 border-2"
                      : "border-gray-300"
                  } w-full flex justify-between cursor-text`}
                >
                  <p>{filterGraphDateFrom || "Default"}</p>
                  <div>
                    <IconCalender />
                  </div>
                </div>
                {/* Reset button */}
                <div onClick={() => setFilterGraphDateFrom(null)} className="cursor-pointer" >
                  <IconXCircle />
                </div>
              </div>
            </div>
            {/* to */}
            <div
              className="flex flex-col items-start space-y-2"
              onClick={() => setSelectedGraphDateFilter(GRAPH_TO_DATE_FILTER)}
            >
              {/* <label className="font-semibold text-lg">Sampai</label>
              <input
              type="date"
              className="w-full rounded-md border border-gray-300 p-2"
              /> */}
              <p>Sampai</p>
              <div className="flex items-center w-full gap-x-2">
                <div
                  className={`p-3 rounded-lg border  ${
                    selectedGraphDateFilter === GRAPH_TO_DATE_FILTER
                      ? "border-primary-500 border-2"
                      : "border-gray-300"
                  } w-full flex justify-between cursor-text`}
                >
                  <p>{filterGraphDateTo || "Default"}</p>
                  <IconCalender />
                </div>
                {/* Reset button */}
                <div onClick={() => setFilterGraphDateTo(null)} className="cursor-pointer">
                  <IconXCircle />
                </div>
              </div>
            </div>
          </div>
          {selectedGraphDateFilter &&
            (selectedGraphDateFilter === GRAPH_FROM_DATE_FILTER ? (
              <MonthYearPicker
                value={filterGraphDateFrom}
                setValue={setFilterGraphDateFrom}
                to={filterGraphDateTo}
              />
            ) : (
              <MonthYearPicker
                value={filterGraphDateTo}
                setValue={setFilterGraphDateTo}
                from={filterGraphDateFrom}
              />
            ))}
          <div className="flex justify-between items-center my-3 w-full" onClick={() => handleApplyGraphDateFilter()}>
            <Button warna="primary">
              <p className="font-semibold">Terapkan</p>
            </Button>
          </div>
        </div>
      </PopupOverlay>
      <div className="px-4 pt-4 pb-10"></div>
      <Navigation />
    </ScreenContainer>
  );
};

export default ManagementMemberPOV;
