import ScreenContainer from "../../components/templates/ScreenContainer";
import finishedWithdrawl from "../../assets/images/finishedWithdrawl.svg";
import Button from "../../components/atoms/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SuccesWithdrawl = () => {
  const { communityId } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  if (!state?.message) {
    window.location.href = `/kas/${communityId}`
  }
  return (
    <ScreenContainer>
      <div className="h-[90vh] overflow-y-auto">
        <div className="flex flex-col items-center justify-center h-full w-full p-6">
          <div>
            <img src={finishedWithdrawl} alt="finished withdrawl" />
          </div>
          <p className="text-center text-2xl font-bold mt-2">
            {state.message}
          </p>
          <div className="my-4 w-full" onClick={() => navigate(`/kas/${communityId}`)} >
            <Button warna="primary" >
              <p>Kembali ke Halaman Komunitas</p>
            </Button>
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
};

export default SuccesWithdrawl;
